import { SET_MESSAGE, CLEAR_MESSAGE , MESSAGE_SUCCESS, MESSAGE_FAILED } from "../actions/types";

const initialState = {};

export default function reducerxxx(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case SET_MESSAGE:
    //   return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

      /// start
    case MESSAGE_SUCCESS:
      return {message: payload};
    
      case MESSAGE_FAILED:
        return {messageFailed: payload}
    // end new 
    default:
      return state;
  }
}
