// import axios from "axios";
import authHeader from "./auth-header";
import api from "./api";

const getLisTransactions = (params) => {
  return api.get("/api/v1/transactions", { headers: authHeader(), params });
};

const getDetailTransactionPhoneCredit = (transactionCode) => {
  return api.get(
    "/api/v1/transaction/detail-phone-credit/" + transactionCode,
    { headers: authHeader() }
  );
};

const getDetailTransactionPlnCredit = (transactionCode) => {
  return api.get("/api/v1/transaction/detail-pln-credit/" + transactionCode, {
    headers: authHeader(),
  });
};

const getDetailTransactionPlnPostPaid = (transactionCode) => {
  return api.get(
    "/api/v1/transaction-detail-pln-postpaid/" + transactionCode,
    { headers: authHeader() }
  );
};

const getDetailBillCommission = (transactionCode) => {
  return api.get("/api/v1/bill-commission/" + transactionCode, {
    headers: authHeader(),
  });
};

const checkStatusTransaction = (transactionCode) => {
  return api.get("/api/v1/check-status/" + transactionCode, {
    headers: authHeader(),
  });
};

const updateStatusAgentPaymentService = (
  transaction_code  
) => {
  return api.put(
    "/api/v1/agent-transaction-payment",
    {
      transaction_code      
    },
    {
      headers: authHeader(),
    }
  );
};

const generateBillCommissionService = (
  user_uuid
) => {
  return api.post(
    "/api/v1/generate-bill-commission",
    {
      user_uuid          
    },
    {
      headers: authHeader(),
    }
  );
};

const transactionObject = {
  getLisTransactions,
  getDetailTransactionPhoneCredit,
  getDetailTransactionPlnCredit,
  checkStatusTransaction,
  getDetailTransactionPlnPostPaid,
  getDetailBillCommission,
  updateStatusAgentPaymentService,
  generateBillCommissionService
};

export default transactionObject;
