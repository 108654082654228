const TermConditions = () => {
  return (
    <div classNameName="container">
      <p>
        Dengan bertransaksi di Juabels maka secara otomatis Anda telah
        menyetujui segala kebijakan yang berlaku disini.
      </p>

      <h2>A. Hal Umum</h2>
      <ol>
        <li>
          Juabels bertindak sebagai perantara antara pembeli dengan penyedia
          layanan operator. Juabels bukan merupakan bagian dari operator.{" "}
        </li>
        <li>
          Hal-hal seperti gangguan operator, sinyal internet lemah, atau keluhan
          lainnya terkait operator merupakan hal diluar kendali Juabels
        </li>
      </ol>

      <h2>B. Limit Kredit </h2>

      <ol>
        <li>
          Juabels memberikan limit kredit kepada suatu agen apabila agen
          tersebut memenuhi syarat yang ditentukan oleh Juabels.
        </li>
        <li>
          {" "}
          Agen bisa memberikan limit kredit pada anggotanya dan limit kredit
          tersebut akan mengurangi total limit kredit agen, dengan kredit
          tersebut anggota bisa melakukan pembelian produk terlebih dahulu dan
          melakukan pembayaran kepada agen di belakang.
        </li>
        <li>
          Maksimal total transaksi Agen adalah sejumlah limit kredit yang
          ditentukan oleh Juabels.
        </li>
        <li>
          Maksimal total transaksi anggota dari suatu agen adalah sejumlah limit
          kredit yang ditentukan oleh Agen.
        </li>
      </ol>

      <h2>C. Pembelian Produk</h2>
      <ol>
        <li>
          Keterangan produk yang ditampilkan di Aplikasi merupakan keterangan
          produk yang diterima dari operator penyedia layanan. Apabila ada
          ketidaksesuaian antara keterangan produk dengan produk yang didapat,
          maka silahkan hubungi operator penyedia layanan untuk mendapat
          informasi lebih lanjut.
        </li>
        <li>
          Harga dapat berubah sewaktu-waktu tanpa pemberitahuan di website
          ataupun aplikasi android. Tergantung kebijakan dari operator penyedia
          layanan.
        </li>
        <li>
          Data pesanan seperti produk yang dipesan dan nomor HP tujuan
          didapatkan dari form order yang sudah diinput oleh pelanggan.
          Kesalahan input data yang mengakibatkan produk tidak dapat terkirim ke
          nomor HP tujuan atau akibat lainnya bukan merupakan tanggung jawab
          Juabels.
        </li>
      </ol>

      <h2>D. Pembayaran Agen kepada Juabels</h2>
      <ol>
        <li>
          Pembayaran dilakukan dengan mata uang Rupiah dan menggunakan uang
          tunai yang diberikan langsung kepada pihak koleksi dari Juabels.
        </li>
        <li>
          Apabila pembeli dari suatu agent tidak melakukan pembayaran produk
          yang sudah berhasil , maka itu akan menjadi tanggung jawab dari agen
          untuk melakukan pembayaran kepada Juabels.
        </li>
        <li>
          Apabila anggota dari suatu agen yang melakukan pembelian produk dengan
          fasilitas kredit dan karena satu dan lain hal pembeli tidak bisa
          melakukan pembayaran, maka pembayaran tersebut menjadi tanggung jawab
          dari agen kepada Juabels.
        </li>
      </ol>

      <h2>E. Pembayaran Pembeli atau Anggota Kepada Agen</h2>
      <ol>
        <li>
          Pembayaran dilakukan dengan mata uang Rupiah dan menggunakan uang
          tunai.
        </li>
      </ol>

      <h2>F. Pembatalan Transaksi</h2>
      <ol>
        <li>
          Produk mengalami gangguan, nomor yang diinput tidak berlaku/diluar
          masa tenggang, atau sebab kuat lainnya maka pembeli berhak melakukan
          pembatalan transaksi.
        </li>
        <li>
          Proses pengisian produk dan belum ada laporan sukses/gagal dari server
          operator, maka pembelian tidak bisa dibatalkan.
        </li>
        <li>
          Juabels berhak melakukan pembatalan transaksi secara sepihak jika
          terjadi pelanggaran oleh Agen dan pembeli.
        </li>
        <li>
          Pembatalan transaksi yang diakibatkan gangguan pada sistem, kenaikan
          harga, atau stok habis, maka uang pemesan akan dikembalikan tanpa
          potongan apapun selambat-lambatnya 2x24 jam sejak pemberitahuan
          pembatalan transaksi dikirim ke pemesan.
        </li>
      </ol>

      <h2>G. Pengiriman Produk</h2>
      <ol>
        <li>
          Produk lama terkirim diakibatkan kesalahan sistem operator, bukan
          merupakan tanggung jawab Juabels.
        </li>
        <li>
          Kesalahan pembelian / order dalam menuliskan Nomor HP, ID PLN, atau
          informasi lainnya yang mengakibatkan produk tidak bisa dikirim menjadi
          tanggung jawab Agen atau pembeli.
        </li>
      </ol>

      <h2>H. Garansi</h2>
      <ol>
        <li>
          Agen dan pelanggan Juabels tidak perlu melakukan pembayaran apabila
          Juabels tidak bisa memproses pemesanan atau karena terjadi suatu
          kesalahan dari sistem Juabels.
        </li>
        <li>
          Garansi yang diberikan Juabels adalah terkait keaslian dan pengiriman
          produk.
        </li>
        <li>
          Jika situs penyedia / provider menghentikan layanan karena masalah
          internalnya, Juabels tidak bertanggung jawab dan tidak dapat dituntut
          memberikan ganti rugi dengan alasan apapun.
        </li>
        <li>Garansi diberikan pada jam kerja yang dicantumkan pada website.</li>
      </ol>

      <h2>I. Informasi Produk</h2>

      <ol>
        <li>Juabels hanya menjual produk yang ada dalam list form order.</li>
        <li>
          Jika terjadi perubahan layanan, fitur, jumlah kuota dan lain-lain,
          maka Juabels tidak bertanggung jawab atas perbedaan/perubahan ini, dan
          tidak dapat dituntut ganti rugi dengan alasan apapun.
        </li>
      </ol>

      <h2>J. Hal Tidak Terduga</h2>
      <ol>
        <li>
          Dalam hal koneksi internet terputus yang mengakibatkan server Juabels
          tidak dapat online sehingga transaksi tidak dapat dilakukan, maka
          Juabels tidak dapat dituntut atas segala kerugian yang ditimbulkan.
        </li>
        <li>
          Juabels tidak bertanggung jawab atas perubahan layanan atau
          penghentian layanan oleh provider dengan alasan apapun.
        </li>
        <li>
          Juabels tidak bertanggung jawab atas terganggunya transaksi akibat
          gangguan pada sistem jaringan internet atau gangguan jaringan
          perbankan atau gangguan jaringan telepon seluler atau sistem penyedia
          situs.
        </li>
      </ol>

      <h2>K. Privasi dan Kerahasiaan</h2>
      <ol>
        <li>Juabels menjamin kerahasiaan dan privasi pelanggan.</li>
        <li>
          Juabels tidak akan membagi informasi apapun seperti email, nomor
          telepon, dan/atau SMS pelanggan kepada pihak manapun kecuali untuk
          keperluan penyidikan pihak berwajib seperti pihak kepolisian dan
          sebagainya.
        </li>
        <li>
          Juabels tidak bertanggung jawab atas terganggunya transaksi akibat
          gangguan pada sistem jaringan internet atau gangguan jaringan
          perbankan atau gangguan jaringan telepon seluler atau sistem penyedia
          situs.
        </li>
      </ol>

      <h2>L. Pemblokiran Akun dan Nomor HP</h2>
      <ol>
        <li>
          Terbukti membuat akun melakukan aktivitas transaksi yang mencurigakan.
        </li>
        <li>
          Akun digunakan untuk menyalahgunakan layanan Juabels seperti menipu,
          dan lain sebagainya.
        </li>
        <li>
          Tidak melakukan pembayaran sesuai dengan jadwal yang telah ditentukan.
        </li>
        <li>dan sebab kuat lainnya.</li>
      </ol>

      <h2>M. Perselisihan</h2>
      <ol>
        <li>
          Perjanjian ini tunduk kepada hukum Negara Kesatuan Republik Indonesia.
        </li>
        <li>
          Apabila terjadi perselisihan maka akan diselesaikan secara musyawarah
          mufakat.
        </li>
      </ol>

      <h2>N. Tambahan</h2>
      <ol>
        <li>
          Hal-hal yang belum tercantum dalam Kebijakan ini akan disampaikan
          kemudian hari.
        </li>
      </ol>
    </div>
  );
};

export default TermConditions;
