import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import DashboardService from "../services/dashboard.service";

function Dashboard() {  
  const [platformGainInThisMonth, setPlatformGainInThisMonth] = useState("");
  const [digiflazzDeposit, setDigiflazzDeposit] = useState("");
  const [partnerGainInThisMonth, setPartnerGainInThisMonth] = useState("");
  const [totalTransactionSuccessToday, setTotalTransactionSuccessToday] =
    useState("");
  const [kiosbankDeposit, setKiosbankDeposit] = useState("");
  const [totalAgent, setTotalAgent] = useState("");
  const [totalDepositUsed, setTotalDepositUsed] = useState("");
  const [totalTransactionSuccessInMonth, setTotalTransactionSuccessInMonth] =
    useState("");
  const [totalNominalTransactionInMonth, setTotalNominalTransactionInMonth] =
    useState("");
  const[totalPartnerActiveTransaction, setTotalPartnerActiveTransaction] = useState("");
  const[totalNominalAmountTransactionThisDay, setTotalNominalAmountTransactionThisDay] = useState("");
  const[totalBillUnpaid, setTotalBillUnpaid] = useState("");  
  const[totalTransactionUnsuccessfullyToday, setTotalTransactionUnsuccessfullyToday] = useState("");

  const getDashboardData = () => {
    DashboardService.getDashboardData()
      .then((response) => {
        if (response) {
          setTotalNominalAmountTransactionThisDay(
            response.data.data.total_nominal_amount_transaction_this_day
          );
          setPlatformGainInThisMonth(
            response.data.data.platform_gain_in_this_month
          );
          setDigiflazzDeposit(response.data.data.digiflazz_deposit);
          setPartnerGainInThisMonth(
            response.data.data.partner_gain_in_this_month
          );
          setTotalTransactionSuccessToday(
            response.data.data.total_transaction_success_today
          );
          setKiosbankDeposit(response.data.data.kiosbank_deposit);
          setTotalAgent(response.data.data.total_agent);
          setTotalDepositUsed(response.data.data.total_deposit_used);
          setTotalTransactionSuccessInMonth(
            response.data.data.total_transaction_success_in_month
          );
          setTotalNominalTransactionInMonth(
            response.data.data.total_nominal_transaction_in_month
          );
          setTotalPartnerActiveTransaction(
            response.data.data.total_partner_active_transaction
          );
          setTotalNominalAmountTransactionThisDay(response.data.data.total_nominal_amount_transaction_this_day);

          setTotalBillUnpaid(response.data.data.total_bill_unpaid);
          setTotalTransactionUnsuccessfullyToday(response.data.data.total_transaction_unsuccessfully_today);

        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(getDashboardData, []);

  return (
    
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-globe text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Mitra</p>
                      <CardTitle tag="p">
                        <b>{totalAgent} / {totalPartnerActiveTransaction} </b>
                        </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="18">
                    <div className="numbers">
                      <p className="card-category">Transaksi Bulan Ini</p>
                      <CardTitle tag="p">
                      <b>{totalTransactionSuccessInMonth} / </b>
                        <b>
                        <NumericFormat
                          displayType="text"                    
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={totalNominalTransactionInMonth}
                        />
                        </b>
                        {}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="14">
                    <div className="numbers">
                      <p className="card-category">Pemakaian Deposit</p>
                      <CardTitle tag="p">
                        <b>
                        <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={totalDepositUsed}
                        />
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="10">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="13">
                    <div className="numbers">
                      <p className="card-category">Deposit</p>
                      <CardTitle tag="p">
                        Kiosbank :{" "}
                        <b>
                        <NumericFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={kiosbankDeposit}
                        />
                        </b>                        
                      </CardTitle>
                      <CardTitle tag="p">
                        Digiflazz :{" "}
                        <b>
                        <NumericFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={digiflazzDeposit}
                        />
                        </b>                        
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>          

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="10" xs="7">
                    <div className="numbers">
                      <p className="card-category">Transaksi Sukses Hari Ini</p>
                      <CardTitle tag="p">
                        <b>{totalTransactionSuccessToday} / <NumericFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={totalNominalAmountTransactionThisDay}
                        />
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="5" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="numbers">
                      <p className="card-category">
                        Pendapatan Juabels Bulan Ini
                      </p>
                      
                      <CardTitle tag="p">
                        <b>
                        <NumericFormat
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={platformGainInThisMonth}
                        />
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="10">
                    <div className="numbers">
                      <p className="card-category">
                        Pendapatan Mitra Bulan Ini
                      </p>
                      <CardTitle tag="p">
                        <b>
                        <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={partnerGainInThisMonth}
                        />
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="10">
                    <div className="numbers">
                      <p className="card-category">
                        Total Tagihan Belum Lunas
                      </p>
                      <CardTitle tag="p">
                        <b>
                        <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={totalBillUnpaid}
                        />
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="10">
                    <div className="numbers">
                      <p className="card-category">
                        Transaksi Tidak Sukses Hari Ini 
                      </p>
                      <CardTitle tag="p">
                        <b>
                        {totalTransactionUnsuccessfullyToday}                        
                        </b>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          

        </Row>
      </div>

  );
}

export default Dashboard;
