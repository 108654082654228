export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";


export const UPDATE_BILL_COMMISSION_PAYMENT_STATUS_FAILED = "UPDATE_BILL_COMMISSION_PAYMENT_STATUS_FAILED";
export const UPDATE_BILL_COMMISSION_PAYMENT_STATUS_SUCCESS = "UPDATE_BILL_COMMISSION_PAYMENT_STATUS_SUCCESS";



export const MESSAGE_FAILED = "MESSAGE_FAILED";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
