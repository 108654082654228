import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import Container from "react-bootstrap/Container";

import { NumericFormat } from "react-number-format";

import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import UserService from "../services/user.service";

function Users() {
  const [listUsers, setListUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getRequestParams = (page, perPage) => {
    let params = {};

    if (page) {
      params["page"] = page;
    }

    if (perPage) {
      params["per_page"] = perPage;
    }

    return params;
  };

  const retrieveUsers = () => {
    const params = getRequestParams(page, perPage);
    UserService.getUsers(params)
      .then((response) => {
        if (response) {
          setListUsers(response.data.data.users);
          setCount(Math.ceil(response.data.data.total / perPage));
        }        
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveUsers, [page, perPage]);

  return (

    
    <Container>



      <Row>
        <CardTitle tag="h4">List Users</CardTitle>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th>Created</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>User Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listUsers.map((item) => (
              <tr key={item.created}>
                
                <td>{item.created}</td>
                <td>{item.full_name} </td>
                <td>{item.email} </td>
                <td>{item.phone_number}</td>
                <td>{item.user_type}</td>
                <td>{item.status} </td>

                <td>
                  <Link
                    to={`/profile/${item.user_uuid}`}
                  >
                    Detail
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          className="my-3"
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </Row>
    </Container>
  );
}

export default Users;
