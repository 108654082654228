import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import TransactionsService from "../services/transactions.service";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { NumericFormat } from "react-number-format";
import { updatePaymentStatusBillCommissionAction } from "../actions/transactions";

function DetailTransactionBillCommission() {
  // The useRef Hook allows you to persist values between renders.
  // It can be used to store a mutable value that does not cause a re-render when updated.
  // Refs are a function provided by React to access the DOM element and the React element that you might have created on your own.
  // They are used in cases where we want to change the value of a child component, without making use of props and all.
  // They have wide functionality as we can use callbacks with them.Jun

  const [transactionCode] = useState(useParams().transaction_code);

  const getDetailBillCommission = () => {
    TransactionsService.getDetailBillCommission(transactionCode)
      .then((response) => {
        setDetailBillCommission(response.data.data.bill_commission);
        setIsPaidAll(response.data.data.is_paid_all);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(getDetailBillCommission, [transactionCode]);

  const [detailBillCommission, setDetailBillCommission] = useState([]);
  const [successful, setSuccessful] = useState(null);
  const [failed, setFailed] = useState(false);

  const [isPaidAll, setIsPaidAll] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);
  const { messageFailed } = useSelector((state) => state.messageFailed);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {
    // Access the value of the clicked button using event.target.value
    // setTransactionCodeToUpdate(transaction_code);
    handleShow();
  };

  let totalAmountTransaction = 0;
  let totalAmountCommission = 0;

  const calculateTotalAmountTransaction = (amount) => {
    totalAmountTransaction += amount;
  };

  const calculateTotalAmountCommission = (amount) => {
    totalAmountCommission += amount;
  };

  const handleUpdateStatusAgentPayment = (e) => {    
    setLoading(true);

    dispatch(updatePaymentStatusBillCommissionAction(transactionCode))
      .then(() => {
        setSuccessful(true);
        setLoading(false);
        getDetailBillCommission(transactionCode);
        
      })
      .catch(() => {
        setSuccessful(false);
        setFailed(true);
        setLoading(false);
      });

    handleClose();
  };

  return (
    <div>
      <div className="col-md-16">
        <header>
          <h3>Detail Bill & Commission</h3>
        </header>

        <Link to={`/transactions/`} className="btn primary">
          Back
        </Link>

        {successful && message && (
          <div className="form-group">
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          </div>
        )}

      {messageFailed && failed && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {messageFailed}
            </div>
          </div>
        )}      

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Description</th>
              <th align="right">Amount</th>
              <th align="right">Commision</th>
              <th align="right">Payment Date</th>
              <th align="center">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {detailBillCommission.map((item) => (
              <tr key={item.transaction_code}>
                <td>{item.description} </td>
                <td align="right">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="'.'"
                    prefix="Rp"
                    value={item.total_amount}
                  />{" "}
                  {calculateTotalAmountTransaction(item.total_amount)}{" "}
                </td>
                <td align="right">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="'.'"
                    prefix="Rp"
                    value={item.agent_commission_amount}
                  />{" "}
                  {calculateTotalAmountCommission(item.agent_commission_amount)}{" "}
                </td>
                <td>{item.agent_payment_date}</td>

                <td align="center">
                  {item.agent_payment_is_paid ? "PAID" : "UNPAID"}
                </td>
              </tr>
            ))}

            <tr>
              <td></td>
              <td align="right">
                <b>
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="'.'"
                    prefix="Rp"
                    value={totalAmountTransaction}
                  />{" "}
                </b>
              </td>
              <td align="right">
                <b>
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="'.'"
                    prefix="Rp"
                    value={totalAmountCommission}
                  />{" "}
                </b>
              </td>
              <td align="left" colSpan={3}>
                {" "}
                <b>
                  Total Tagihan :{" "}
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="'.'"
                    prefix="Rp"
                    value={totalAmountTransaction - totalAmountCommission}
                  />{" "}
                </b>
              </td>
            </tr>

            <tr>
              <td align="left" colSpan={6}>
                <div className="form-group">
                  <button
                    className="btn btn-primary btn-block"                    
                    disabled={isPaidAll}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Update all transaction to PAID </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Agent Payment Confirmation!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to update satus payment of agent ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateStatusAgentPayment}>
              Update Status
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default DetailTransactionBillCommission;
