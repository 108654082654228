const ContactUs = () => {
  return (
    
      <div class="container px-5">

          
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <a
            class="me-lg-3 mb-4 mb-lg-0"
            href="#"
          >
            <img class="app-badge" src="undraw_Mail_re_duel.png" alt="..." />
          </a>

          
          <p class="lead fw-normal text-muted mb-5 mb-lg-0">
            <b>Kirimkan pertanyaan anda kepada kami : </b> tim@juabels.com
          </p>

        </div>

        

        
      </div>
      
    

    
  );
};

export default ContactUs;
