const Home = () => {
  return (
    <div classNameName="container">
      <section class="bg-light">
        <div class="container px-5">
          <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div class="col-12 col-lg-5">
              <h2 class="display-6 lh-1 mb-4">Jual Pulsa dan Token Listrik</h2>
              <p class="lead fw-normal text-muted mb-5 mb-lg-0">
                Jual pulsa, token listrik, paket data, bayar tagihan listrik
                tanpa perlu top up.
              </p>
              <br />
              <div class="d-flex flex-column flex-lg-row align-items-center">
                <a
                  class="me-lg-3 mb-4 mb-lg-0"
                  href="https://play.google.com/store/apps/details?id=com.juabels.juabels"                  
                >
                  <img class="app-badge" src="playstore.png" alt="..." />
                </a>
              </div>
            </div>

            <div class="col-sm-8 col-md-6">
              <div class="px-5 px-sm-0">
                <img
                  class="img-fluid"
                  src="juabels_home_screen.png"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
