import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import UserService from "../services/user.service";
import { Link, useParams } from "react-router-dom";
import { updateProfile } from "../actions/users";


const Profile = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [userUuid] = useState(useParams().user_uuid);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [created, setCreated] = useState("");
  const [updated, setUpdated] = useState("");
  const [userType, setUserType] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [creditLimitAvailable, setCreditLimitAvailable] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [failed, setFailed] = useState(false);
  const { message } = useSelector((state) => state.message);
  const { messageFailed } = useSelector((state) => state.messageFailed);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeCreditLimit = (e) => {
    const creditLimit = e.target.value;
    setCreditLimit(creditLimit);
  };

  const status = [
    { value: "PENDING", text: "PENDING" },
    { value: "ACTIVE", text: "ACTIVE" },
    { value: "INACTIVE", text: "INACTIVE" },
  ];

  const statusOptions = status.map((statusOptions) => {
    return (
      <option key={statusOptions.value} value={statusOptions.value}>
        {statusOptions.text}
      </option>
    );
  });

  const [statusValue, setStatusValue] = useState("");

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    setSuccessful(false);
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(updateProfile(creditLimit, statusValue, userUuid))
        .then(() => {
          setSuccessful(true);
          setLoading(false);
        })
        .catch(() => {
          setFailed(true);
          setSuccessful(false);
          setLoading(false);
        });
    }
  };

  const getUserProfile = () => {
    UserService.getUserProfile(userUuid)
      .then((response) => {
        if (response) {
          setEmail(response.data.data.email);
          setFullName(response.data.data.full_name);
          setPhoneNumber(response.data.data.phone_number);
          setCreated(response.data.data.created);
          setCreated(response.data.data.created);
          setUpdated(response.data.data.updated);
          setCreditLimit(response.data.data.credit_limit);
          setUserType(response.data.data.user_type);
          setStatusValue(response.data.data.status);
          setCreditLimitAvailable(response.data.data.credit_limit_available);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(getUserProfile, [userUuid]);

  return (
    <div>
      <header>
        <h3>User Profile</h3>
      </header>

      <Link to={`/users`} className="btn primary">
        Back
      </Link>

      <div className="col-md-8">
        {message && successful && (
          <div className="form-group">
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          </div>
        )}

        {messageFailed && failed && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {messageFailed}
            </div>
          </div>
        )}

        <div>
          <Form onSubmit={handleUpdateProfile} ref={form}>
            <div>
              <div className="form-group">
                <label htmlFor="fullname">Full Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fullname"
                  value={fullName}
                  disabled
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <Input
                  type="phoneNumber"
                  className="form-control"
                  name="phoneNumber"
                  value={phoneNumber}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <label htmlFor="creditLimitAvailable">
                  Credit Limit Available
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="creditLimitAvailable"
                  value={creditLimitAvailable}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <label htmlFor="userType">User Type</label>
                <Input
                  type="text"
                  className="form-control"
                  name="userType"
                  value={userType}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <label htmlFor="status">Status</label>

                <select
                  class="form-control"
                  id="user_status"
                  name="user_status"
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  {statusOptions}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="creditLimit">Credit Limit</label>
                <Input
                  type="text"
                  className="form-control"
                  name="creditLimit"
                  value={creditLimit}
                  onChange={onChangeCreditLimit}
                />
              </div>

              <div className="form-group">
                <label htmlFor="created">Created</label>
                <Input
                  type="text"
                  className="form-control"
                  name="created"
                  value={created}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <label htmlFor="updated">Updated</label>
                <Input
                  type="text"
                  className="form-control"
                  name="updated"
                  value={updated}
                  disabled
                  Readonly
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Update Profile</span>
                </button>
              </div>
            </div>

            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
