import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import TransactionsService from "../services/transactions.service";
import { ArrowClockwise } from "react-bootstrap-icons";
import { checkStatusTransaction } from "../actions/transactions";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";

function DetailTransactionPhoneCredit() {
  const [transactionCode, setTransactionCode] = useState(
    useParams().transaction_code
  );
  const [transactionStatus, setTransactionStatus] = useState("");
  const [productName, setProductName] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [agentPaymentDate, setAgentPaymentDate] = useState("");
  const [transactionFeeAmount, setTransactionFeeAmount] = useState("");
  const [agentCommissionAmount, setAgentCommissionAmount] = useState("");
  const [agentPaymentIsPaid, setAgentPaymentIsPaid] = useState("");
  const [created, setCreated] = useState("");
  const [customerPaymentDate, setCustomerPaymentDate] = useState("");
  const [productSellingPrice, setProductSellingPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TransactionsService.getDetailTransactionPhoneCredit(transactionCode)
      .then((response) => {
        if (response) {
          setAgentPaymentDate(response.data.data.agent_payment_date);
          setAgentCommissionAmount(response.data.data.agent_commission_amount);
          setTransactionStatus(response.data.data.transaction_status);
          setTransactionStatus(response.data.data.transaction_status);
          setProductName(response.data.data.product_name);
          setcustomerId(response.data.data.customer_id);
          setAgentPaymentIsPaid(response.data.data.agent_payment_is_paid);
          setCreated(response.data.data.created);
          setCustomerPaymentDate(response.data.data.customer_payment_date);
          setTotalAmount(response.data.data.total_amount);
          setTransactionFeeAmount(response.data.data.transaction_fee_amount);
          setTransactionCode(response.data.data.transaction_code);
          setSerialNumber(response.data.data.serial_number);
          setProductSellingPrice(response.data.data.product_selling_price);
          setTransactionType(response.data.data.transaction_type);          
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const dispatch = useDispatch();

  const handleCheckStatus = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(checkStatusTransaction(transactionCode))
      .then(() => {
        setLoading(false);
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
<div className="col-md-8">
        <Link to={`/transactions/`} className="btn primary">
          Back
        </Link>

        <Table striped>
          <thead>
            <tr>
              <th colSpan={2}>Status Transaksi</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Status</td>
              <td align="right"> {transactionStatus} </td>
            </tr>

            <tr>
              <td colSpan={2}>
                {" "}
                <b>Waktu dan Status Pembayaran</b>
              </td>
            </tr>

            <tr>
              <td>Status Pembayaran Anggota</td>
              <td align="right">{customerPaymentDate}</td>
            </tr>

            <tr>
              <td>Tanggal/Waktu</td>
              <td align="right">{customerPaymentDate}</td>
            </tr>

            <tr>
              <td>Status Pembayaran Mitra</td>
              <td align="right">{agentPaymentIsPaid}</td>
            </tr>

            <tr>
              <td>Tanggal/Waktu</td>
              <td align="right">{agentPaymentDate}</td>
            </tr>

            <tr>
              <td colSpan={2}>
                {" "}
                <b>Informasi Pelanggan</b>
              </td>
            </tr>

            <tr>
              <td>Nomor Ponsel</td>
              <td align="right">{customerId}</td>
            </tr>

            <tr>
              <td colSpan={2}>
                {" "}
                
                {transactionType === "PURCHASE_SMS_PHONE_PACKAGE" && (
                <b>Informasi SMS / Telepon</b>
                )}

                {transactionType === "PURCHASE_PHONE_CREDIT" && (
                <b>Informasi Pulsa</b>
                )}

                {transactionType === "PURCHASE_PHONE_DATA" && (
                <b>Informasi Paket Data</b>
                )}

              </td>
            </tr>

            <tr>
              <td align="right" colSpan={2}>
                {" "}
                {productName}{" "}
              </td>
            </tr>

            <tr>
              <td colSpan={2}>Detail Transaksi</td>
            </tr>

            <tr>
              <td>Tanggal/Waktu</td>
              <td align="right">{created}</td>
            </tr>

            <tr>
              <td>No Referensi</td>
              <td align="right">{transactionCode}</td>
            </tr>

            <tr>
              <td>Serial Number</td>
              <td align="right">{serialNumber}</td>
            </tr>

            <tr>
              <td>Harga Pulsa</td>
              <td align="right">
              <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={productSellingPrice}
                        />
                         </td>
            </tr>

            <tr>
              <td>Biaya Transaksi</td>
              <td align="right">
              <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={transactionFeeAmount}
                        />
                        </td>
            </tr>

            <tr>
              <td>
                <b>Total Transaksi</b>
              </td>
              <td align="right">
                <b align="right">                  
                  <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={totalAmount}
                        />
                  </b>
              </td>
            </tr>

            <tr>
              <td>Komisi Mitra</td>
              <td align="right">{}
              
                        <NumericFormat
                          displayType="text"                          
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="Rp"
                          value={agentCommissionAmount}
                        />
                        </td>
            </tr>

            {transactionStatus === "Dalam Proses" && (
              <tr>
                <td colSpan={2}>
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                    onClick={handleCheckStatus}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>
                      Perbaharui Status <ArrowClockwise />
                    </span>
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default DetailTransactionPhoneCredit;
