const PrivacyPolicy = () => {
  return (
    <div classNameName="container">
      <p>
        Adanya Kebijakan Privasi ini adalah komitmen nyata dari Juabels untuk
        menghargai dan melindungi setiap data atau informasi pribadi Pengguna
        situs Juabels.com, situs-situs turunannya, serta aplikasi android
        (selanjutnya disebut sebagai "Situs").
      </p>
      <p>
        Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari situs
        Juabels sebagaimana tercantum dalam "Syarat & Ketentuan" dan informasi
        lain yang tercantum di Situs) menetapkan dasar atas perolehan,
        pengumpulan, pengolahan, penganalisisan, penampilan, pembukaan, dan/atau
        segala bentuk pengelolaan yang terkait dengan data atau informasi yang
        Pengguna berikan kepada Juabels atau yang Juabels kumpulkan dari
        Pengguna, termasuk data pribadi Pengguna, baik pada saat Pengguna
        melakukan pendaftaran di Situs, mengakses Situs, maupun mempergunakan
        layanan-layanan pada Situs (selanjutnya disebut sebagai "data").
      </p>
      <p>
        Dengan mengakses dan/atau mempergunakan layanan Juabels, Pengguna
        menyatakan bahwa setiap data Pengguna merupakan data yang benar dan sah,
        serta Pengguna memberikan persetujuan kepada Juabels untuk memperoleh,
        mengumpulkan, menyimpan, mengelola dan mempergunakan data tersebut
        sebagaimana tercantum dalam Kebijakan Privasi maupun Syarat dan
        Ketentuan Juabels.
      </p>

      <h2>A. Perolehan dan Pengumpulan Data Pengguna</h2>
      <ol>
        <li>
          Juabels mengumpulkan data Pengguna dengan tujuan untuk memproses
          transaksi Pengguna, mengelola dan memperlancar proses penggunaan
          Situs, serta tujuan-tujuan lainnya selama diizinkan oleh peraturan
          perundang-undangan yang berlaku. Adapun data Pengguna yang dikumpulkan
          adalah sebagai berikut:Data yang diserahkan secara mandiri oleh
          Pengguna, termasuk namun tidak terbatas pada data yang diserahkan pada
          saat Pengguna :
        </li>
        <ol>
          <li>
            Membuat atau memperbarui akun Juabels, termasuk diantaranya nama
            pengguna, alamat email, nomor telepon, password, alamat tempat
            tinggal, dan lain-lain;
          </li>
          <li>Menghubungi Juabels, termasuk melalui layanan Pengguna;</li>
          <li>
            Mempergunakan layanan-layanan pada Situs, termasuk data transaksi
            yang detail, diantaranya jenis, jumlah dan/atau keterangan dari
            produk atau layanan yang dibeli, jumlah transaksi, tanggal dan waktu
            transaksi, serta detail transaksi lainnya;
          </li>
          <li>
            Menggunakan fitur yang membutuhkan izin akses terhadap perangkat
            Pengguna;
          </li>
        </ol>
        <li>
          Data yang terekam pada saat Pengguna mempergunakan Situs, termasuk
          namun tidak terbatas pada :
        </li>
        <ol>
          <li>
            Data berupa waktu dari setiap aktivitas Pengguna, termasuk aktivitas
            pendaftaran, login, transaksi, dan lain sebagainya;
          </li>
          <li>
            Data penggunaan atau preferensi Pengguna, diantaranya interaksi
            Pengguna dalam menggunakan situs, pilihan yang disimpan, serta
            pengaturan yang dipilih.
          </li>
          <li>
            Data perangkat, diantaranya jenis perangkat yang digunakan untuk
            mengakses situs, termasuk model perangkat keras, sistem operasi dan
            versinya, perangkat lunak, nama file dan versinya, pilihan bahasa,
            pengenal perangkat unik.
          </li>
          <li>
            Data catatan (log), diantaranya catatan pada server yang menerima
            data seperti alamat IP perangkat, tanggal dan waktu akses, fitur
            situs atau laman yang dilihat, proses kerja situs dan aktivitas
            sistem lainnya.
          </li>
        </ol>
      </ol>

      <h2>B. Penggunaan Data </h2>

      <ol>
        <li>
          Juabels dapat menggunakan keseluruhan atau sebagian data yang
          diperoleh dan dikumpulkan dari Pengguna sebagaimana disebutkan dalam
          bagian sebelumnya untuk hal-hal sebagai berikut : Memproses segala
          bentuk permintaan, aktivitas maupun transaksi yang dilakukan oleh
          Pengguna melalui situs, termasuk untuk keperluan pengiriman produk
          kepada Pengguna.
        </li>
        <li>
          {" "}
          Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara dan
          memperbaiki produk dan layanan kami, termasuk:
        </li>
        <ol>
          <li>
            Memungkinkan fitur untuk mempribadikan akun Juabels Pengguna,
            seperti daftar Favorit; dan/atau
          </li>
          <li>
            Melakukan kegiatan internal yang diperlukan untuk menyediakan
            layanan pada situs Juabels, seperti pemecahan masalah software, bug,
            permasalahan operasional, melakukan analisis data, pengujian, dan
            penelitian, dan untuk memantau dan menganalisis kecenderungan
            penggunaan dan aktivitas.
          </li>
          <li>
            Membantu Pengguna pada saat berkomunikasi dengan Layanan Pengguna
            Juabels, diantaranya untuk:
          </li>
        </ol>
        <ol>
          <li>Memeriksa dan mengatasi permasalahan Pengguna.</li>
          <li>
            Mengarahkan pertanyaan Pengguna kepada petugas Layanan yang tepat
            untuk mengatasi permasalahan.
          </li>
          <li>Mengawasi dan memperbaiki tanggapan Layanan Pengguna Juabels.</li>
          <li>
            Menghubungi Pengguna melalui email, surat, telepon, aplikasi
            pengirim pesan, dan lain-lain, termasuk namun tidak terbatas, untuk
            membantu dan/atau menyelesaikan proses transaksi maupun proses
            penyelesaian kendala.
          </li>
          <li>
            Menggunakan informasi yang diperoleh dari Pengguna untuk tujuan
            penelitian, analisis, pengembangan dan pengujian produk guna
            meningkatkan keamanan dan keamanan layanan-layanan pada Situs, serta
            mengembangkan fitur dan produk baru.
          </li>
          <li>
            Menginformasikan kepada Pengguna terkait produk, layanan, promosi,
            berita, perkembangan terbaru, acara dan lain-lain.
          </li>
          <li>
            Melakukan monitoring ataupun investigasi terhadap
            transaksi-transaksi mencurigakan atau transaksi yang terindikasi
            mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan
            Ketentuan atau ketentuan hukum yang berlaku, serta melakukan
            tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil
            monitoring atau investigasi transaksi tersebut.
          </li>
          <li>
            Dalam keadaan tertentu, Juabels mungkin perlu untuk menggunakan
            ataupun mengungkapkan data Pengguna untuk tujuan penegakan hukum
            atau untuk pemenuhan persyaratan hukum dan peraturan yang berlaku,
            termasuk dalam hal terjadinya sengketa atau proses hukum antara
            Pengguna dan Juabels.
          </li>
        </ol>
      </ol>

      <h2>C. Pengungkapan Data Pribadi Pengguna</h2>
      <ol>
        <li>
          Juabels menjamin tidak ada penjualan, pengalihan, distribusi atau
          meminjamkan data pribadi Anda kepada pihak ketiga lain, tanpa terdapat
          izin dari Anda, kecuali dalam hal-hal sebagai berikut :
        </li>
        <p>
          Dibutuhkan adanya pengungkapan data Pengguna kepada mitra atau pihak
          ketiga lain yang membantu Juabels dalam menyajikan layanan pada Situs
          dan memproses segala bentuk aktivitas Pengguna dalam Situs, termasuk
          memproses transaksi pembelian produk.
        </p>
        <li>
          Juabels dapat menyediakan informasi yang relevan kepada mitra usaha
          dari Juabels sesuai dengan persetujuan Pengguna untuk menggunakan
          layanan mitra usaha, termasuk diantaranya aplikasi atau situs lain
          yang telah saling mengintegrasikan API atau layanannya, atau mitra
          usaha yang mana Juabels telah bekerjasama.
        </li>
        <li>
          Dibutuhkan adanya komunikasi antara mitra usaha Juabels (seperti
          pembayaran produk, status transaksi pembelian produk, dan lain-lain
          yang berhubungan dengan transaksi) dengan Pengguna dalam hal
          penyelesaian kendala maupun hal-hal lainnya.
        </li>
        <li>
          Pengguna menghubungi Juabels melalui media sosial, dan fitur tertentu
          pada Situs mungkin dapat dilihat secara publik.
        </li>
        <li>
          Juabels mengungkapkan data Pengguna dalam upaya mematuhi kewajiban
          hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum.
        </li>
      </ol>

      <h2>D. Pilihan Pengguna dan Transparansi</h2>
      <ol>
        <li>
          Perangkat seluler pada umumnya (Android, dan sebagainya) memiliki
          pengaturan sehingga situs Juabels tidak dapat mengakses data tertentu
          tanpa persetujuan dari Pengguna. Perangkat Android akan memberikan
          pemberitahuan kepada pengguna saat pengguna menekan tombol kontak
          untuk pertama kali. Dengan menggunakan aplikasi dan memberikan akses
          terhadap aplikasi, Pengguna dianggap memberikan persetujuannya
          terhadap Kebijakan Privasi.
        </li>
        <li>
          Pengguna dapat mengakses dan mengubah informasi berupa alamat email,
          nomor telepon, alamat dan nama Pengguna dengan menghubungi layanan
          Pengguna.
        </li>
        <li>
          Juabels dapat mengirimkan pesan atau email berupa keterangan transaksi
          atau informasi terkait akun Pengguna.
        </li>
        <li>
          Sejauh diizinkan oleh ketentuan yang berlaku, Pengguna dapat
          menghubungi Juabels untuk melakukan penarikan persetujuan terhadap
          perolehan, pengumpulan, penyimpanan, pengelolaan dan penggunaan data
          Pengguna. Apabila terjadi demikian maka Pengguna memahami konsekuensi
          bahwa Pengguna tidak dapat menggunakan layanan Situs maupun layanan
          Juabels lainnya.
        </li>
      </ol>

      <h2>E. Penyimpanan dan Penghapusan Informasi</h2>
      <ol>
        <li>
          Juabels akan menyimpan informasi profil akun selama akun pengguna
          tetap aktif. Data transaksi secara berkala akan dihapus dan hanya
          menyisakan data transaksi beberapa minggu yang paling baru. Juabels
          dapat melakukan penghapusan informasi akun jika pemilik akun
          mengajukan penghapusan atau tutup akun. Dalam kasus lain, jika dirasa
          akun tersebut menyalahi ketentuan dari Juabels maka akun akan dihapus
          tanpa pemberitahuan.
        </li>
      </ol>

      <h2>F. Pembaruan Kebijakan Privasi</h2>
      <ol>
        <li>
          Juabels dapat sewaktu-waktu melakukan perubahan dan / atau pembaruan
          terhadap Kebijakan Privasi ini. Juabels menyarankan agar Pengguna
          membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini
          secara berkala dari waktu ke waktu untuk mengetahui perubahan apapun
          yang mungkin terjadi. Dengan tetap mengakses dan menggunakan layanan
          Situs, maupun layanan Juabels lainnya, maka Pengguna dianggap
          menyetujui perubahan-perubahan dalam Kebijakan Privasi.
        </li>
        <li>Kebijakan Privasi ini berlaku mulai 11 February 2023.</li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
