//import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { actionGenerateBillCommissionService } from "../actions/transactions";
import UserService from "../services/user.service";
// import { CLEAR_MESSAGE } from "../actions/types";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import Button from "react-bootstrap/Button";

const GenerateBill = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [successful, setSuccessful] = useState(false);
  const [failed, setFailed] = useState(false);

  const { message } = useSelector((state) => state.message);
  const { messageFailed } = useSelector((state) => state.messageFailed);

  const [disableButtonGenerate, setDisableButtonGenerate] = useState(true);

  const [listUser, setListuser] = useState([]);
  const [userUuid, setUserUuid] = useState();
  const [fullName, setFullName] = useState();

  const handleSelectedUser = (e) => {
    setUserUuid(e.value);
    setFullName(e.label);
    setDisableButtonGenerate(false);
  };

  const dispatch = useDispatch();

  const handleActionGenerateBillCommission = (e) => {
    handleShow();
  };

  const handleActionToGenerateBillCommission = (e) => {
    handleShow();
    dispatch(actionGenerateBillCommissionService(userUuid))
      .then(() => {
        setSuccessful(true);
        handleClose();
      })
      .catch(() => {
        setSuccessful(false);
        setFailed(true);
        handleClose();
      });

    handleClose();
  };

  const handleGetUser = (e) => {
    const arrayOfObjects = [];
    UserService.searchUser(e.value)
      .then((response) => {
        if (response) {
          response.data.data.users.map((row) => {
            const obj = {};
            obj["label"] = row.full_name;
            obj["value"] = row.user_uuid;
            arrayOfObjects.push(obj);
          });
          setListuser(arrayOfObjects);
        }
      })
      .catch((e) => {
      });
  };

  return (
    <div>
      <header>
        <h3>Generate Bill for Partner (On Demand)</h3>
      </header>

      <div className="col-md-8">
        {message && successful && (
          <div className="form-group">
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          </div>
        )}

        {messageFailed && failed && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {messageFailed}
            </div>
          </div>
        )}      

        <div>
          <div>
            <div className="form-group">
              <label htmlFor="status">Partner Name</label>

              <Select
                onInputChange={handleGetUser}
                options={listUser}
                onChange={handleSelectedUser}
              />
            </div>

            <div className="form-group">
              <button
                onClick={handleActionGenerateBillCommission}
                className="btn btn-primary btn-block"
                disabled={disableButtonGenerate ? disableButtonGenerate : false}
              >
                <span>Generate Bill & Commision</span>
              </button>
            </div>
          </div>

          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation to genereate Bill</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure to genereate Bill & Commission for {fullName} ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleActionToGenerateBillCommission}
              >
                Generate Bill & Commision
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default GenerateBill;
