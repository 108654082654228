import React, { useState, useEffect } from "react";
import { CardTitle, Table, Row } from "reactstrap";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import TransactionsService from "../services/transactions.service";
import UserService from "../services/user.service";
import Form from "react-bootstrap/Form";
function Transactions() {
  
  const transactionStatus = [
    { value: "SUCCESS", label: "SUCCESS" },
    { value: "FAILED", label: "FAILED" },
    { value: "ON_PROCESS", label: "ON PROCESS" },
  ];

  const transactionType = [
    { value: "PURCHASE_PHONE_CREDIT", label: "Pulsa" },
    { value: "PURCHASE_PHONE_DATA", label: "Paket Data" },
    { value: "PURCHASE_PLN_CREDIT", label: "Token" },
    { value: "AGENT_FEE", label: "Komisi" },
    { value: "BILLING", label: "Tagihan Mitra" },    
    { value: "PAY_PLN_POSTPAID", label: "Tagihan Listrik" },
    { value: "PURCHASE_SMS_PHONE_PACKAGE", label: " Paket SMS / Telepon" },
  ];

  const [userUuid, setUserUuid] = useState();
  const [transactionStatusValue, setTransactionStatusValue] = useState();
  const [transactionTypeValue, setTransactionTypeValue] = useState();
  const [keywords, setKeywords] = useState("");
  const [listUser, setListuser] = useState([]);
  const [listTransactions, setListTransaction] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  
  
  const handleSelectedUser = (e) => {
    setUserUuid(e.value);
  };

  const handleGetUser = (e) => {
    const arrayOfObjects = [];
    UserService.searchUser(e.value)
      .then((response) => {
        if (response) {
          response.data.data.users.map((row) => {
            const obj = {};
            obj["label"] = row.full_name;
            obj["value"] = row.user_uuid;
            arrayOfObjects.push(obj);
          });
          setListuser(arrayOfObjects);
        }
      })
      .catch((e) => {});
  };

  
  const [perPage] = useState(10);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getRequestParams = (page, perPage, keywords, transactionStatusValue, transactionTypeValue, userUuid) => {
    let params = {};

    if (page) {
      params["page"] = page;
    }

    if (perPage) {
      params["per_page"] = perPage;
    }

    if (keywords) {
      params["keywords"] = keywords;
    }

    if (transactionStatusValue) {
      params["transaction_status"] = transactionStatusValue;
    }
    if (transactionTypeValue) {
      params["transaction_type"] = transactionTypeValue;
    }

    if (userUuid) {
      params["user_uuid"] = userUuid;
    }

    return params;
  };

  const handleSelectedStatus = (e) => {
    setTransactionStatusValue(e.value);
  };

  const handleSelectedTransactionType = (e) => {
    setTransactionTypeValue(e.value);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const retrieveTransactions = () => {
    const params = getRequestParams(page, perPage, keywords, transactionStatusValue, transactionTypeValue, userUuid);
    TransactionsService.getLisTransactions(params)
      .then((response) => {
        if (response) {
          setListTransaction(response.data.data.transactions);
          setCount(Math.ceil(response.data.data.total / perPage));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveTransactions, [page, perPage, keywords, transactionStatusValue, transactionTypeValue, userUuid]);

  return (
    <div>
      <div className="col-md-14">
        <CardTitle tag="h4">List Transactions </CardTitle>
        <Row>
          <div className="col-md-2">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Keywords</Form.Label>

              <Form.Control
                type="text"
                name="keywords"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                placeholder="keywords.."
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="status">Partner Name</label>

              <Select
                onInputChange={handleGetUser}
                options={listUser}
                onChange={handleSelectedUser}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="status">Transaction Type</label>
              <Select
                options={transactionType}
                name="transaction_type"
                onChange={handleSelectedTransactionType}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="status">Transaction Status</label>

              <Select
                options={transactionStatus}
                name="transaction_status"
                onChange={handleSelectedStatus}
                // onChange={(e) => setTransactionStatusValue(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="status">&nbsp;</label>

              <button className="btn btn-primary btn-block" onClick={handleReloadPage}>
                <span>Reload Page</span>
              </button>
            </div>
          </div>


          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Created</th>
                <th>Agent</th>
                <th>Description</th>
                <th>Amount</th>                
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {listTransactions.map((item) => (
                <tr key={item.transaction_code}>
                  <td>{item.created}</td>
                  <td>{item.user_full_name} </td>
                  <td>{item.description} </td>
                  <td align="right">
                    <b>
                      <NumericFormat
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="Rp"
                        value={item.total_amount}
                      />
                    </b>{" "}
                  </td>
                  <td>{item.transaction_status} </td>
                  <td>

                  {item.transaction_type === "PURCHASE_SMS_PHONE_PACKAGE" && (
                      <Link
                        to={`/detail-transaction/phone-credit/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}


                    {item.transaction_type === "PURCHASE_PHONE_CREDIT" && (
                      <Link
                        to={`/detail-transaction/phone-credit/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}

                    {item.transaction_type === "PURCHASE_PHONE_DATA" && (
                      <Link
                        to={`/detail-transaction/phone-credit/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}

                    {item.transaction_type === "PURCHASE_PLN_CREDIT" && (
                      <Link
                        to={`/detail-transaction/pln-credit/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}

                    {item.transaction_type === "PAY_PLN_POSTPAID" && (
                      <Link
                        to={`/transaction-detail-pln-postpaid/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}

                    {item.transaction_type === "AGENT_FEE" && (
                      <Link
                        to={`/transaction-detail-bill-commission/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}

                    {item.transaction_type === "BILLING" && (
                      <Link
                        to={`/transaction-detail-bill-commission/${item.transaction_code}`}
                      >
                        Detail
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </Row>
      </div>
    </div>
  );
}

export default Transactions;
