// import axios from "axios";
import authHeader from "./auth-header";
import api from "./api";
//const API_URL = process.env.REACT_APP_API_URL;
 
const getDashboardData = (params) => {
  return api.get("/api/v1/dashboard", {
    headers: authHeader(),
    params,
  });
};



const userObject = {
  getDashboardData
};

export default userObject;
