import { SET_MESSAGE, CLEAR_MESSAGE , MESSAGE_SUCCESS, MESSAGE_FAILED} from "./types";

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});



export const messageSuccess = (message) => ({
  type: MESSAGE_SUCCESS,
  payload: message,
});

export const messageFailed = (message) => ({
  type: MESSAGE_FAILED,
  payload: message,
});
