import authHeader from "./auth-header";
import api from "./api";
//const API_URL = process.env.REACT_APP_API_URL;

const updateProfile = (credit_limit, member_status, user_uuid) => {
  //return axios.put(API_URL + "/api/v1/user/update-profile-user", {    
  
  return api.put("/api/v1/user/update-profile-user", {    
        credit_limit,
    member_status,
    user_uuid    
  }, {
    headers: authHeader()
  }
  );
};

const getUsers = (params) => {
  //return axios.get(API_URL + "/api/v1/users", {
    return api.get("/api/v1/users", {
    headers: authHeader(),
    params,
  });
};

const getUserProfile = (userUuid) => {
  let params = {};
  params["user_uuid"] = userUuid;
  return api.get("/api/v1/user/profile", {
    headers: authHeader(),
    params,
  });
};

const searchUser = (keywords) => {
  let params = {};
  params["keywords"] = keywords;
  params["page"] = 1;
  params["per_page"] = 100;
  // {{ _.host_dev }}api/v1/users?page=1&per_page=3&keywords=skdsdkfjsdkfjk
  return api.get("/api/v1/users", {
    headers: authHeader(),
    params,
  });
};

const userObject = {
  getUsers,
  getUserProfile,
  updateProfile,
  searchUser
};

export default userObject;
