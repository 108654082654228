import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    UPDATE_BILL_COMMISSION_PAYMENT_STATUS_SUCCESS,
    UPDATE_BILL_COMMISSION_PAYMENT_STATUS_FAILED,
    MESSAGE_SUCCESS,
    MESSAGE_FAILED
  } from "./types";
  
  import TransactionsService from "../services/transactions.service";
  
  export const checkStatusTransaction = (transactionCode) => (dispatch) => {
    return TransactionsService.checkStatusTransaction(transactionCode).then(
      (response) => {
        
        // dispatch({
        //   type: REGISTER_SUCCESS,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });
  
        return Promise.resolve();
      },
      (error) => {
        // const message =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
  
        // dispatch({
        //   type: REGISTER_FAIL,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: message,
        // });
  
        return Promise.reject();
      }
    );
  };


  // return UserService.updateProfile(credit_limit, member_status, user_uuid).then(
  export const updatePaymentStatusBillCommissionAction = (transaction_code) => (dispatch) => {
    
    return TransactionsService.updateStatusAgentPaymentService(transaction_code).then(
      (response) => {
        // dispatch({
        //   type: UPDATE_BILL_COMMISSION_PAYMENT_STATUS_SUCCESS,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });

        dispatch({
          type: MESSAGE_SUCCESS,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // dispatch({
        //   type: UPDATE_BILL_COMMISSION_PAYMENT_STATUS_FAILED,
        // });
  
        dispatch({
          type: MESSAGE_FAILED,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  
  export const actionGenerateBillCommissionService = (creditLimit, status, userUuid) => (dispatch) => {
    
    const credit_limit = creditLimit;
    const user_uuid = userUuid;
    const member_status = status

    return TransactionsService.generateBillCommissionService(credit_limit, member_status, user_uuid).then(
      (response) => {
        // dispatch({
        //   type: UPDATE_PROFILE_SUCCESS,
        // });
  
        dispatch({
          type: MESSAGE_SUCCESS,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: MESSAGE_FAILED,
          payload:message
        });
  
        
  
        return Promise.reject();
      }
    );
  };
  