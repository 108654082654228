import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import TransactionsService from "../services/transactions.service";
import { Link } from "react-router-dom";
import { checkStatusTransaction } from "../actions/transactions";
import { ArrowClockwise } from "react-bootstrap-icons";
import { NumericFormat } from "react-number-format";
function DetailTransactionPlnCredit() {
  const [transactionCode, setTransactionCode] = useState(
    useParams().transaction_code
  );
  const [transactionStatus, setTransactionStatus] = useState("");
  const [adminBank, setAdminBank] = useState("");
  const [token, setToken] = useState("");
  const [infoText, setInfoText] = useState("");
  const [ppj, setPpj] = useState("");
  const [rate, setRate] = useState("");
  const [installment, setInstallment] = useState("");
  const [stamp, setStamp] = useState("");
  const [rpStroomToken, setRpStroomToken] = useState("");
  const [customerIdNumber, setCustomerIdNumber] = useState("");
  const [power, setPower] = useState("");
  const [ppn, setPpn] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [kwhValue, setKwhValue] = useState("");
  const [name, setName] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [meterNumber, setMeterNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [productDenominationPrice, setProductDenominationPrice] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [agentPaymentDate, setAgentPaymentDate] = useState("");
  const [transactionFeeAmount, setTransactionFeeAmount] = useState("");
  const [agentCommissionAmount, setAgentCommissionAmount] = useState("");
  const [agentPaymentIsPaid, setAgentPaymentIsPaid] = useState("");
  const [customerPaymentIsPaid, setCustomerPaymentIsPaid] = useState("");
  const [created, setCreated] = useState("");
  const [customerPaymentDate, setCustomerPaymentDate] = useState("");
  const [productSellingPrice, setProductSellingPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [serialNumber, setSerialNumber] = useState("");

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   TransactionsService.getDetailTransactionPlnCredit(transactionCode)
  //     .then((response) => {
  //       if (response) {
  //         setAdminBank(response.data.data.admin_bank);
  //         setToken(response.data.data.token);
  //         setInfoText(response.data.data.info_text);
  //         setPpj(response.data.data.ppj);
  //         setRate(response.data.data.rate);
  //         setInstallment(response.data.data.installment);
  //         setStamp(response.data.data.stamp);
  //         setRpStroomToken(response.data.data.rp_stroom_token);
  //         setCustomerIdNumber(response.data.data.customer_id_number);
  //         setPower(response.data.data.power);
  //         setPpn(response.data.data.ppn);
  //         setTotalPayment(response.data.data.total_ayment);
  //         setKwhValue(response.data.data.kwh_value);
  //         setName(response.data.data.name);
  //         setReferenceNumber(response.data.data.reference_number);
  //         setMeterNumber(response.data.data.meter_number);
  //         setAgentPaymentDate(response.data.data.agent_payment_date);
  //         setAgentCommissionAmount(response.data.data.agent_commission_amount);
  //         setTransactionStatus(response.data.data.transaction_status);
  //         setTransactionStatus(response.data.data.transaction_status);
  //         setProductName(response.data.data.product_name);
  //         setcustomerId(response.data.data.customer_id);
  //         setAgentPaymentIsPaid(response.data.data.agent_payment_is_paid);
  //         setCreated(response.data.data.created);
  //         setCustomerPaymentDate(response.data.data.customer_payment_date);
  //         setTotalAmount(response.data.data.total_amount);
  //         setTransactionFeeAmount(response.data.data.transaction_fee_amount);
  //         setTransactionCode(response.data.data.transaction_code);
  //         setSerialNumber(response.data.data.serial_number);
  //         setProductSellingPrice(response.data.data.product_selling_price);
  //         setProductDenominationPrice(
  //           response.data.data.product_denomination_price
  //         );
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  const dispatch = useDispatch();

  const handleCheckStatus = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(checkStatusTransaction(transactionCode))
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDetailTransactionPlnCredit = () => {
    TransactionsService.getDetailTransactionPlnCredit(transactionCode)
      .then((response) => {
        if (response) {
          setAdminBank(response.data.data.admin_bank);
          setToken(response.data.data.token);
          setInfoText(response.data.data.info_text);
          setPpj(response.data.data.ppj);
          setRate(response.data.data.rate);
          setInstallment(response.data.data.installment);
          setStamp(response.data.data.stamp);
          setRpStroomToken(response.data.data.rp_stroom_token);
          setCustomerIdNumber(response.data.data.customer_id_number);
          setPower(response.data.data.power);
          setPpn(response.data.data.ppn);
          setTotalPayment(response.data.data.total_ayment);
          setKwhValue(response.data.data.kwh_value);
          setName(response.data.data.name);
          setReferenceNumber(response.data.data.reference_number);
          setMeterNumber(response.data.data.meter_number);
          setAgentPaymentDate(response.data.data.agent_payment_date);
          setAgentCommissionAmount(response.data.data.agent_commission_amount);
          setTransactionStatus(response.data.data.transaction_status);
          setTransactionStatus(response.data.data.transaction_status);
          setProductName(response.data.data.product_name);
          setcustomerId(response.data.data.customer_id);
          setAgentPaymentIsPaid(response.data.data.agent_payment_is_paid);
          setCreated(response.data.data.created);
          setCustomerPaymentDate(response.data.data.customer_payment_date);
          setCustomerPaymentIsPaid(response.data.data.customer_payment_is_paid);
          setTotalAmount(response.data.data.total_amount);
          setTransactionFeeAmount(response.data.data.transaction_fee_amount);
          setTransactionCode(response.data.data.transaction_code);
          setSerialNumber(response.data.data.serial_number);
          setProductSellingPrice(response.data.data.product_selling_price);
          setProductDenominationPrice(
            response.data.data.product_denomination_price
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(getDetailTransactionPlnCredit, [transactionCode]);

  return (
    <div>
      <div className="col-md-8">
        <Link to={`/transactions`} className="btn primary">
          Back
        </Link>

        <Table striped>
          <thead>
            <tr>
              <th colSpan={2}>Status Transaksi</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Status</td>
              <td align="right"> {transactionStatus} </td>
            </tr>

            <tr>
              <td colSpan={2}>
                {" "}
                <b>Waktu dan Status Pembayaran</b>
              </td>
            </tr>

            <tr>
              <td>Status Pembayaran Anggota</td>
              <td align="right">{customerPaymentIsPaid}</td>
            </tr>

            <tr>
              <td>Tanggal/Waktu</td>
              <td align="right">{customerPaymentDate}</td>
            </tr>

            <tr>
              <td>Status Pembayaran Mitra</td>
              <td align="right">{agentPaymentIsPaid}</td>
            </tr>

            <tr>
              <td>Tanggal/Waktu</td>
              <td align="right">{agentPaymentDate}</td>
            </tr>

            <tr>
              <td colSpan={2}>
                {" "}
                <b>Struk Pembelian Listrik Prabayar</b>
              </td>
            </tr>

            <tr>
              <td>Nomor Meter</td>
              <td align="right">{meterNumber}</td>
            </tr>

            <tr>
              <td>ID Pelanggan</td>
              <td align="right">{customerId}</td>
            </tr>

            <tr>
              <td>Nama Pelanggan</td>
              <td align="right">{name}</td>
            </tr>

            <tr>
              <td>Tarif/Daya</td>
              <td align="right">{rate} / {power}</td>
            </tr>

            <tr>
              <td> Tanggal/Waktu Pembelian</td>
              <td align="right">{created}</td>
            </tr>

            <tr>
              <td>No Referensi</td>
              <td align="right">{transactionCode}</td>
            </tr>

            <tr>
              <td>No Referensi Biller</td>
              <td align="right">{referenceNumber}</td>
            </tr>

            <tr>
              <td>Materai</td>
              <td align="right">{stamp}</td>
            </tr>

            <tr>
              <td>PPN</td>
              <td align="right">{ppn}</td>
            </tr>

            <tr>
              <td>PPJ</td>
              <td align="right">{ppj}</td>
            </tr>

            <tr>
              <td>Angsuran</td>
              <td align="right">{installment}</td>
            </tr>

            <tr>
              <td>RP Stroom Token</td>
              <td align="right">{rpStroomToken}</td>
            </tr>

            <tr>
              <td>Info Text</td>
              <td align="right">{infoText}</td>
            </tr>

            <tr>
              <td>Jumlah KWH</td>
              <td align="right">{kwhValue}</td>
            </tr>

            <tr>
              <td>Stroom / Token</td>
              <td align="right">
                <b>{token}</b>
              </td>
            </tr>

            <tr>
              <td>Nominal</td>
              <td align="right">
                <NumericFormat
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp"
                  value={productDenominationPrice}
                />
              </td>
            </tr>

            <tr>
              <td>Admin Bank</td>
              <td align="right">
                <NumericFormat
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp"
                  value={adminBank}
                />{" "}
              </td>
            </tr>

            <tr>
              <td>
                <b>Total Transaksi</b>
              </td>
              <td align="right">
                <b>
                  <NumericFormat
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="Rp"
                    value={totalAmount}
                  />
                </b>
              </td>
            </tr>

            <tr>
              <td>Komisi Mitra</td>
              <td align="right">
                <NumericFormat
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp"
                  value={agentCommissionAmount}
                />
              </td>
            </tr>
            {transactionStatus === "Dalam Proses" && (
              <tr>
                <td colSpan={2}>
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                    onClick={handleCheckStatus}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>
                      Perbaharui Status <ArrowClockwise />
                    </span>
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default DetailTransactionPlnCredit;
