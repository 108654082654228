import {
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_SUCCESS,
    SET_MESSAGE,
    MESSAGE_FAILED,
    MESSAGE_SUCCESS,
  } from "./types";
  
  import UserService from "../services/user.service";
  
  export const updateProfile = (creditLimit, status, userUuid) => (dispatch) => {
    
    const credit_limit = creditLimit;
    const user_uuid = userUuid;
    const member_status = status

    return UserService.updateProfile(credit_limit, member_status, user_uuid).then(
      (response) => {
        // dispatch({
        //   type: UPDATE_PROFILE_SUCCESS,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });


        dispatch({
          type: MESSAGE_SUCCESS,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        // dispatch({
        //   type: UPDATE_PROFILE_FAILED,
        // });
  
        dispatch({
          type: MESSAGE_FAILED,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };