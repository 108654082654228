import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Transactions from "./components/Transactions";
import Profile from "./components/Profile";
import DetailTransactionPhoneCredit from "./components/DetailTransactionPhoneCredit";
import DetailTransactionPLnCredit from "./components/DetailTransactionPlnCredit";
import DetailTransactionBillCommission from "./components/DetailTransactionBillCommission";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import EventBus from "./common/EventBus";
import DetailTransactionPlnPostPaid from "./components/DetailTransactionPlnPostPaid";
import Users from "./components/Users";
import Home from "./components/Home";
import TermConditions from "./components/TermConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";

import GenerateBill from "./components/GenerateBill";


import { Navigate, useNavigate } from "react-router-dom";

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (["/login"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (
      [
        "/dashboard",
        "/transactions",
        "/users",
        "/transaction-detail-bill-commission",
        "/detail-transaction",
        "transaction-detail-pln",
        "/transaction-detail-pln-postpaid",
        "/profile",
      ].includes(location.pathname) &&
      !currentUser
    ) {
      navigate("/");
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    // if (currentUser) {
    //   // setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
    //   // setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    // } else {
    // }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark navbar-custom">
        <Link to={"/"} className="navbar-brand">
          Juabels
        </Link>

        <div className="navbar-nav">
          {!currentUser && (
            <li className="nav-item">
              <Link to={"/about-us"} className="nav-link">
                Tentang Kami
              </Link>
            </li>
          )}
          {!currentUser && (
            <li className="nav-item">
              <Link to={"/contact-us"} className="nav-link">
                Kontak
              </Link>
            </li>
          )}
          {!currentUser && (
            <li className="nav-item">
              <Link to={"/term-condition"} className="nav-link">
                Syarat dan ketentuan
              </Link>
            </li>
          )}
          {!currentUser && (
            <li className="nav-item">
              <Link to={"/privacy-policy"} className="nav-link">
                Kebijakan Privasi
              </Link>
            </li>
          )}
        </div>

        <div className="navbar-nav mr-auto">
          {currentUser && (
            <li className="nav-item">
              <Link to={"/dashboard"} className="nav-link">
                Dashboard
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/transactions"} className="nav-link">
                Transactions
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/generate-bill"} className="nav-link">
                Bill
              </Link>
            </li>
          )}


          {currentUser && (
            <li className="nav-item">
              <Link to={"/users"} className="nav-link">
                Users
              </Link>
            </li>
          )}
          
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <Link to={"#"} className="nav-link">
              </Link>
            </li> */}
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                Sign Out
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto"></div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/detail-transaction/phone-credit/:transaction_code"
            element={<DetailTransactionPhoneCredit />}
          />
          <Route
            path="/detail-transaction/pln-credit/:transaction_code"
            element={<DetailTransactionPLnCredit />}
          />
          <Route
            path="/transaction-detail-pln-postpaid/:transaction_code"
            element={<DetailTransactionPlnPostPaid />}
          />
          <Route
            path="/transaction-detail-bill-commission/:transaction_code"
            element={<DetailTransactionBillCommission />}
          />
          <Route path="/users" element={<Users />} />
          <Route path="/profile/:user_uuid" element={<Profile />} />
          <Route path="/term-condition" element={<TermConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/generate-bill" element={<GenerateBill />} />

        </Routes>
      </div>
    </div>
  );
};

export default App;
