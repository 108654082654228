export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.access_token) {
    
     const header =  { 
      Authorization: "Bearer " + user.access_token, 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "Content-type,X-Auth-Token Origin,Authorization",
            
    };
    return header;
  } else {
    return {};
  }
}
