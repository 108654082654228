import React, { useState, useEffect } from "react";

const AboutUs = () => {
  return (
    <div classNameName="container">
      <div class="col-sm-10 col-md-10">
        
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <a
            class="me-lg-3 mb-4 mb-lg-0"
            href="#"
          >
            <img class="app-badge" src="undraw_Engineering_team_a7n2.png" alt="..." />
          </a>

         

        </div>

        

        
      </div>

      <div class="col-sm-10 col-md-10">
 
      <h2>Juabels.com adalah agen penjual Pulsa dan Token listrik </h2>
        <p>
          Kami menyediakan aplikasi dan  pendanaan untuk berjualan pulsa, paket
          data , token listrik, dan pembayaran tagihan listrik.
        </p>
    </div>  
    </div>
  );
};

export default AboutUs;
