import axios from "axios";

//const API_URL = process.env.REACT_APP_API_URL;


const login = (email, password) => {
  return axios
    //.post(API_URL + "/api/v1/user/login", { email: email, password:password,
    .post("/api/v1/user/login", { email: email, password:password,
    })
    .then((response) => {
      if (response.data.data) {
        
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  // call api logout
};
const authObject = {
  login,
  logout,
};

export default authObject
 